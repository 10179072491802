/* HEAD:
 * This file contains initialization for frameworks and some general purpose functions
 */

function sendError(data) {
    let message;

    if (Object.prototype.hasOwnProperty.call(data.responseJSON, "message") && data.responseJSON.message !== "") {
        const errorArray = [];
        Object.keys(data.responseJSON.errors).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(data.responseJSON.errors, key)) {
                errorArray.push(data.responseJSON.errors[key]);
            }
        });

        message = errorArray.join("\n");
    } else {
        const isFirefox = typeof InstallTrigger !== "undefined"; // Firefox 1.0+

        // Chrome 1 - 79
        const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        message = trans.forms["something-went-wrong"];

        // If not on Firefox or chrome also add recomendation to use a different browser.
        if (!isFirefox && !isChrome) {
            message = `${message} ${trans.forms["please-use-our-browser"]}`;
        }
    }
    Swal.fire("Oops", message, "error");
}
window.sendError = sendError;

/* Provides an general function to delete something of type target with id: id.
 * Eglible targets are : advices, exercises, exercise lists, products
 */
function deleteItem(id, target) {
    const userType = window.therapist_custom === true ? "therapist" : "admin";

    $.ajax({
        url: `/${userType}/${target}/remove`,
        method: "post",
        headers: {
            "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content")
        },
        data: { id }
    })
        .done(_ => {
            let DOMId;
            if (target.localeCompare("exercise") === 0) {
                DOMId = `#admin-${target}-${id}`;
            } else {
                DOMId = `#${target}-${id}`;
            }
            $(DOMId).remove();
            Swal.fire({
                title: "",
                text: target.charAt(0).toUpperCase() + target.slice(1) + trans.forms["has-been-deleted"],
                icon: "success"
            });
        })
        .fail((data, _) => {
            sendError(data);
        });
}

/* Presents a prompt before deleting an item.
 */
function promptDelete(item) {
    const id = $(item).data("id");
    const target = $(item).data("type");

    Swal.fire({
        title: trans["you-sure"],
        text: trans.forms["want-to-delete"],
        icon: "warning",
        buttons: { no: trans.forms["never-mind"], yes: trans.forms.yes }
    }).then(result => {
        if (result === "yes") {
            deleteItem(id, target);
        }
    });
}
window.promptDelete = promptDelete;

/*
 * Fetches the data for an item and inserts it into it's corresponding modal on the admin.
 */
function getMetadata(item) {
    const userType = window.therapist_custom === true ? "therapist" : "admin";

    // Fetches the ID of the HTML item.
    const id = $(item).data("id");

    // Fetches the target type of the HTML item.
    const target = $(item).data("type");

    // Hides the empty form of the modal, shows the loading text and opens the modal.
    $(`#${target}Modal form`).hide();
    $(`#${target}Modal .loading`).show();

    // Makes a AJAX call to the corresponding route for the item.
    $.ajax({
        url: `/${userType}/${target}/get`,
        method: "post",
        headers: {
            "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content")
        },
        data: { id },
        error: data => {
            sendError(data);
        },
        complete: response => {
            const data = response.responseJSON;

            // Puts in the received data into the modal that most items have.
            $(`#${target}Modal input[name='id']`).val(data.id);
            $(`#${target}Modal input[name='title_nl']`).val(data.title_nl);
            $(`#${target}Modal input[name='title_en']`).val(data.title_en);
            $(`#${target}Modal textarea[name='description_nl']`).val(data.description_nl);
            $(`#${target}Modal textarea[name='description_en']`).val(data.description_en);

            window.image_list_original = null;
            if (data.image_list) {
                $("#image_list_preview").attr("src", data.image);
                window.image_list_original = $("#image_list_preview").attr("src");
                $("#list-preview").css("display", "block");
            }

            // Depending on what item type it is, some more field may need to be filled in.
            switch (target) {
                case "exercise":
                    window.insertExerciseData(data, target);
                    break;
                case "list":
                    window.fillExerciseListSettings(data);
                    break;
                case "product":
                    $(`#${target}Modal input[name='name_nl']`).val(data.name_nl);
                    $(`#${target}Modal input[name='name_en']`).val(data.name_en);
                    $(`#${target}Modal textarea[name='description_nl']`).val(data.description_nl);
                    $(`#${target}Modal textarea[name='description_en']`).val(data.description_en);
                    $(`#${target}Modal input[name='price']`).val(data.price);
                    break;
                case "advice":
                    $(`#${target}Modal option[value=${data.advice_type}]`).attr("selected", "selected");
                    $(`#${target}Modal input[name='name_nl']`).val(data.name_nl);
                    $(`#${target}Modal input[name='name_en']`).val(data.name_en);
                    $(`#${target}Modal input[name='video']`).val(data.video);
                    if (data.lists.length > 0) {
                        $("#list_id_modal").val(data.lists[0].id);
                        $("#list_id_name_modal").val(data.lists[0].title);
                    }
                    break;
                default:
            }
        }
    });

    // Hide the loading text and show the modal form.
    $(`#${target}Modal .loading`).hide();
    $(`#${target}Modal form`).show();
}
window.getMetadata = getMetadata;

/*
 * Serialises a form as an object, just like a normal form submit would.
 */
$.fn.serializeObject = function () {
    const o = {};
    const a = this.serializeArray();
    a.forEach(obj => {
        if (o[obj.name] !== undefined) {
            if (!o[obj.name].push) {
                o[obj.name] = [o[obj.name]];
            }
            o[obj.name].push(obj.value || "");
        } else {
            o[obj.name] = obj.value || "";
        }
    });
    return o;
};

/*
 * Helper to the testImage function.
 */
function testImageHelper(url) {
    // Define the promise
    const imgPromise = new Promise((resolve, reject) => {
        // Create the image
        const imgElement = new Image();

        // When image is loaded, resolve the promise
        imgElement.addEventListener("load", function () {
            resolve(this);
        });

        // When there's an error during load, reject the promise
        imgElement.addEventListener("error", () => {
            reject();
        });

        // Assign URL
        imgElement.src = url;
    });

    return imgPromise;
}

/*
 * Loads the given gif when you hover over the picture with your mouse.
 */
function loadGif(id, url) {
    const source = $(id).attr("src");
    $(id)
        .mouseenter(() => {
            $(id).attr("src", url);
        })
        .mouseleave(() => {
            $(id).attr("src", source);
        });
}

/*
 * Tests an image for existence.
 * If it doesn't exist a dummy picture will be inserted after the element class.
 */
function testImage(url, element, stdClasses, alt, id, gif, gifSource) {
    testImageHelper(url).then(
        // Inserts the image into the DOM.
        img => {
            element.html(img);
            const image = element.children("img");
            image.addClass(stdClasses);
            image.attr("alt", alt);
            image.attr("id", id);

            // image.attr("crossorigin", "anonymous");

            if (gif) {
                loadGif(`#${id}`, gifSource);
            }
        },

        /*
         * Inserts the standard (questionmark) image into the space
         * where the original would be.
         */
        () => {
            const imgElement = new Image();
            imgElement.src = "/storage/img/question.jpg";
            $(element).append(imgElement);
            $(element).children("img").addClass(stdClasses);
            $(element).children("img").attr("alt", trans.forms["img-not-found"]);
        }
    );
}

/*
 * Replaces the available spaces for arguments in the localisation string
 * corresponding to the object arguments given in a JSON format: (arg_key : arg_value)
 */
function jsLocalArguments(localString, args) {
    let tmpString = localString;
    // for (const key in args) {
    Object.keys(args).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(args, key)) {
            tmpString = tmpString.replace(`:${key}`, args[key]);
        }
    });

    return tmpString;
}

function toggle(target) {
    $(target).toggle();
}

/*
 * Checks whether a user is on mobile based on regex.
 */
window.mobilecheck = () => {
    let check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            // eslint-disable-next-line no-useless-escape
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);

    return check;
};

window.toggle = toggle;
window.jsLocalArguments = jsLocalArguments;
window.testImage = testImage;
window.loadGif = loadGif;

/*
 * Sets a a cookie given a name, value and number of days.
 */
function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
}
window.setCookie = setCookie;

window.firstDateIsPastDayComparedToSecond = (firstDate, secondDate) =>
    firstDate.setHours(0, 0, 0, 0) - secondDate.setHours(0, 0, 0, 0) < 0;

// Privacy Modal
// function loadPrivacyModal() {
//     const wrapper = crel("div");
//     wrapper.innerHTML = trans.legal["privacy-agreement"];

//     Swal.fire({
//         title: "Privacy statement",
//         content: wrapper,
//         type: "warning",
//         closeOnClickOutside: false,
//         buttons: {
//             agree: {
//                 text: trans.legal["privacy-agreement-agree"]
//             }
//         }
//     }).then(_ => {
//         setCookie("privacy-agree", "true", 365);
//     });
// }

/*
 * Retrieves the value of the cookie by its key.
 */
function getCookie(cname) {
    const name = `${cname}=`;
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

/*
 * General message
 */

/*
 * Hide the general message
 */
function hideGeneralMessage() {
    $("#general-message").addClass("d-none");
}
window.hideGeneralMessage = hideGeneralMessage;

/* The general message has a counter; if the counter is the same as in the cookie, we remove the message.
 * If it is not the same or it is not set at all, then we set a listener on the removal button.
 * We set a cookie when the message remove button is clicked.
 */
function checkCookieGeneralMessage() {
    const count = getCookie("general-message");

    if (count === window.generalMessageCounter) {
        hideGeneralMessage();
    } else if (count === "" || count !== window.generalMessageCounter) {
        $("#remove-general-message").on("click", () => {
            window.hideGeneralMessage();
            window.setCookie("general-message", window.generalMessageCounter, 10);
        });
    }
}

/*
 * Launches the privacy/ general messages dependant on cookies.
 */
$(window).on("load", () => {
    // Check if the user agreed to the privacy agreement.
    // Apparently not needed anymore because there is a checkbox in registering now
    // checkCookiePrivacy();
    checkCookieGeneralMessage();
});

/*
 * When the DOM with all its elements are loaded.
 */
$(function () {
    $(document)
        .ajaxStart(() => {
            $("#loading").show();
        })
        .ajaxStop(() => {
            $("#loading").hide();
        });

    $("#nameInput").on("click", function () {
        $(this).trigger("focus");
    });

    // (function () {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll(".needs-validation");

    // Loop over them and prevent submission
    Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
            "submit",
            function (event) {
                if (!form.checkValidity()) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add("was-validated");
            },
            false
        );
    });
});
